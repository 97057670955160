import { template as template_7ecab0f136144e529323e6f0ce7d4c33 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7ecab0f136144e529323e6f0ce7d4c33(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
