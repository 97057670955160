import { template as template_dea05ded1e4f41da82d715488d4e1ead } from "@ember/template-compiler";
const FKLabel = template_dea05ded1e4f41da82d715488d4e1ead(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
